import React from 'react'
import { size } from 'lodash'
import classNames from 'classnames'
import { ProjectFragment } from 'Typings/generated-gatsby-types'
import AltPageLayout, { isFirstSectionHasLabel } from 'Components/layout/AltPageLayout'
import DynamicSections from 'Components/sections/DynamicSections'
import { localImageUrl } from 'Utils/api'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Menu from 'Components/layout/Menu'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import { FormSection } from 'Components/sections/FormSection'
import { Divider } from 'Components/Divider/Divider'
import styles from './ProjectPage.module.scss'

export type ProjectPageProps = {
  project: ProjectFragment
  path: string
}

const ProjectPage = (props: ProjectPageProps) => {
  const { project, path } = props

  const isInit = useMaybeChangeLanguageOnInit()

  if (!isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={{
          meta_title: project.name,
          meta_description: project.short_info,
          meta_image: project.preview,
        }}
        path={path}
        title={project.name}
        subTitle={project.work_type}
        navs={['portfolio']}
        firstSectionHasLabel={isFirstSectionHasLabel(project.sections_start)}
      >
        <DynamicSections className={styles.dynamic} sections={project.sections_start} />
        <BaseContainer
          className={classNames([
            styles.views,
            {
              [styles.viewsNoTopIndents]: size(project.sections_start) > 0,
              [styles.viewsNoBottomIndents]: size(project.sections_end) > 0,
            },
          ])}
          contentClassName={styles.viewsContent}
          vertical="sm"
        >
          {project.views.map((i, index) => (
            <img key={index} className={styles.view} src={localImageUrl(i)} />
          ))}
        </BaseContainer>
        <DynamicSections
          className={classNames([styles.dynamic, styles.dynamicFooter])}
          sections={project.sections_end}
        />
        <Divider />
        <FormSection />
      </AltPageLayout>
    </>
  )
}

export default ProjectPage
